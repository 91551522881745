@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');

*{
  font-family: "Nunito Sans", sans-serif;
  
}

.hero-section {
  background-size: cover;
  background-position: center;
}

.hero-section h1, .hero-section p {
  color: #FFFFFF;
}

form input, form textarea {
  border-radius: 0.375rem;
  padding: 0.5rem;
  width: 100%;
}

form button {
  background-color: #2D7C36;
  border: none;
  color: white;
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
}

form button:hover {
  background-color: #40B93C;
}



@keyframes slideUp {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slideDown {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(0);
  }
}

.header-slide-up {
  animation: slideUp 0.5s forwards;
}

.header-slide-down {
  animation: slideDown 0.5s forwards;
}

